import "./CSS/Mountains.css";

function Mountains(props) {
  let dT = props.dT

  if (dT) {
    return (
      <div className="bottom">
        <div className="Mountains-Mobile"></div>
        <div className="Information">
          <div id="NameAJ">Ana & Jesualdo</div>
          <div id="WeddingDate">February 22, 2022</div>
          <div id="DaysLeft">102 Days to Go</div>
          <button className="rsvpBtn">- RSVP -</button>
        </div>
      </div>
    );
  } else {
    return (
        <div className="bottom">
          <div className="Mountains-Desktop"></div>
          <div className="Information-Desktop">
            <div id="NameAJ-Desktop">Ana & Jesualdo</div>
            <div id="WeddingDate-Desktop">February 22, 2022</div>
            <div id="DaysLeft-Desktop">102 Days to Go</div>
          </div>
        </div>
      );
  }
}

export default Mountains;
