import './CSS/EndOfPage.css';

function EndOfPage () {
    return (
    <div className="EndOfPage">
    <div className="Company">Emi Services</div>
    <div className="About">About</div>
    <div className="ContactUs">Contact Us</div>
    </div>
    )
}

export default EndOfPage;