import "./CSS/NavigationBar.css";

function NavigationBar(props) {
  const homeClick = () => {
    alert("Home");
  };
  const rsvpClick = () => {
    alert("RSVP");
  };
  const eventClick = () => {
    alert("Event");
  };
  const registryClick = () => {
    alert("Registry");
  };
  const contactClick = () => {
    alert("Contact");
  };

  if (props.dT) {
    return (
      <nav className="hamMenu" role="navigation">
        <div id="menuToggle">
          <input type="checkbox" />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <a onClick={homeClick}>
              <li>Home</li>
            </a>
            <a onClick={rsvpClick}>
              <li>RSVP</li>
            </a>
            <a onClick={eventClick}>
              <li>Event</li>
            </a>
            <a onClick={registryClick}>
              <li>Registry</li>
            </a>
            <a onClick={contactClick}>
              <li>Contact</li>
            </a>
          </ul>
        </div>
      </nav>
    );
  } else {
    return (
      <ul id="menu-Desktop">
        <a onClick={homeClick}>
          <li>Home</li>
        </a>
        <a onClick={rsvpClick}>
          <li>RSVP</li>
        </a>
        <a onClick={eventClick}>
          <li>Event</li>
        </a>
        <a onClick={registryClick}>
          <li>Registry</li>
        </a>
        <a onClick={contactClick}>
          <li>Contact</li>
        </a>
      </ul>
    );
  }
}

export default NavigationBar;
