import './CSS/Title.css';

function Title() {
    const topTitle = "A & J"
    return (
        <div className="Title">
            {topTitle}
        </div>
    )
}

export default Title;