import './CSS/CoupleImage.css';

function CoupleImage(props) {
    if (props.dT) {
        return (
            <div className="ImageContainer"></div>
        )
    } else {
        return (
            <div className="DesktopImageContainer"></div>
        )
    }

}

export default CoupleImage;